<template>
  <v-card tile elevation="0">
    <v-alert type="error" tile class="mb-0">
      {{ $t("form_error.title") }}
    </v-alert>
    <v-card-text class="alert-body" color="error">
      <ul ref="errors"></ul>
    </v-card-text>
  </v-card>
</template>

<script>
import { lowerCase } from "lodash";

export default {
  props: {
    errors: Array
  },
  watch: {
    errors() {
      // Clear the previous errors
      this.$refs.errors.innerText = "";
      this.errors.forEach(({ label, message }) => {
        if (!message) return;
        this.$refs.errors.insertAdjacentHTML(
          "beforeend",
          `<li>${this.$t("form_error.message", {
            message: lowerCase(message),
            label
          })}</li>`
        );
      });
    }
  }
};
</script>

<i18n>
{
  "en": {
		"form_error": {
			"title": "There was an error with your submission",
			"message": "\"{label}\" {message}"
		}
	},
	"ja": {
		"form_error": {
			"title": "フォームはまだ送信されていません",
			"message": "「{label}」{message}"
		}
	}
}
</i18n>

<style lang="scss" scoped>
.alert-body {
  // TODO: Change this to variable (can't figure out how to do it)
  background: #ff52520d;
  color: var(--v-error-base) !important;
}
</style>
